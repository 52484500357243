<template>
  <v-container fluid>
    <v-card tile>

      <v-toolbar>
        <v-btn icon to="/admin/registrations" exact>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>Edit registration</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-container v-if="loading">
          <v-row class="fill-height" align-content="center" justify="center">
            <v-col class="subtitle-1 text-center" cols="12">
              Loading data
            </v-col>
            <v-col cols="6">
              <v-progress-linear
                color="deep-purple accent-4"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-container>

        <v-form v-else @submit.prevent="saveRegistration">
          <v-text-field v-model="registration.name" label="Name"></v-text-field>
          <v-text-field v-model="registration.surname" label="Surname"></v-text-field>
          <v-text-field v-model="registration.email" label="E-mail" type="email"></v-text-field>
          <v-text-field v-model="registration.roomNumber" label="Room number"></v-text-field>
          <v-text-field v-model="registration.dateOfBirth" label="Date of birth" type="date"></v-text-field>
          <v-text-field v-model="registration.placeOfBirth" label="Place of birth"></v-text-field>
          <v-text-field v-model="registration.permanentAddress" label="Permanent address"></v-text-field>
          <v-btn type="submit" color="success">Save</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      loading: true,
      registration: null
    }
  },
  methods: {
    async saveRegistration () {
      await this.$store.dispatch('registration/updateRegistration', this.registration)
      await this.$router.push('/admin/registrations')
    }
  },
  async mounted () {
    document.title = 'Edit Registration | Masarka Student Club'
    this.loading = true
    this.registration = await this.$store.dispatch('registration/fetchRegistrationById', this.$route.query.id)
    this.loading = false
  }
}
</script>
